<template>
  <component
    :is="componentName"
    :setting-values="theme.settings"
    @update-theme-settings="$emit('update-theme-settings', $event)"
  />
</template>

<script>
import GetThemeNameByThemeId from '@/graphQlQueries/queries/getThemeNameByThemeId';

export default {
  name: 'ThemeSettingsWrapper',
  components: {
    direct: () => import('@/components/settings/projects/themes/ThemeSettingsDirect.vue'),
    direct_top: () => import('@/components/settings/projects/themes/ThemeSettingsDirectTop.vue'),
    direct_top2: () => import('@/components/settings/projects/themes/ThemeSettingsDirectTop2.vue'),
    direct_top_bmw: () => import('@/components/settings/projects/themes/ThemeSettingsDirectTopBmw.vue'),
    direct_top_easy: () => import('@/components/settings/projects/themes/ThemeSettingsDirectTopEasy.vue'),
    direct_top_tef: () => import('@/components/settings/projects/themes/ThemeSettingsDirectTopTef.vue'),
    direct_top_tef2: () => import('@/components/settings/projects/themes/ThemeSettingsDirectTopTef2.vue'),
    direct_bottom: () => import('@/components/settings/projects/themes/ThemeSettingsDirectBottom.vue'),
    direct_right: () => import('@/components/settings/projects/themes/ThemeSettingsDirectRight.vue'),
  },
  props: {
    theme: {
      themeId: {
        type: Number,
        required: true,
        default: null,
      },
      settings: {
        type: Object,
        default: null,
      },
    },
  },
  data() {
    return {
      componentName: null,
    };
  },
  apollo: {
    themeName: {
      query: GetThemeNameByThemeId,
      variables() {
        return {
          themeId: this.theme.themeId,
        };
      },
      update(data) {
        this.componentName = data.themes_by_pk.name;
        return data.themes_by_pk.name;
      },
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
