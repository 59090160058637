var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.componentName, {
    tag: "component",
    attrs: { "setting-values": _vm.theme.settings },
    on: {
      "update-theme-settings": function($event) {
        return _vm.$emit("update-theme-settings", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }